import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { rhythm } from '../utils/typography';

const StyledHeading = styled.h1`
	margin-bottom: ${rhythm(1.5)};
	margin-top: 0;
`;

const StyledLink = styled(Link)`
	box-shadow: none;
	text-decoration: none;
	color: inherit;
`;

export default function Header(props) {
	const { title } = props;

	return (
		<header>
			<StyledHeading>
				<StyledLink to={`/`}>{title}</StyledLink>
			</StyledHeading>
		</header>
	);
}
