import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { rhythm } from '../utils/typography';

const BlogPostTitle = styled.h3`
	margin-bottom: ${rhythm(1 / 4)};
`;

const BlogPostLink = styled(Link)`
	box-shadow: none;
`;

export default class BlogIndex extends React.Component {
	render() {
		const { data } = this.props;
		const siteTitle = data.site.siteMetadata.title;
		const posts = data.allContentfulBlogPost.edges;

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title="All posts" />

				<h2>Recent Blog Posts</h2>

				{posts.map(({ node }) => {
					const title = node.title || node.slug;

					return (
						<div key={node.slug}>
							<BlogPostTitle>
								<BlogPostLink to={`/${node.slug}`}>{title}</BlogPostLink>
							</BlogPostTitle>

							<small>{node.createdDate}</small>

							<p
								dangerouslySetInnerHTML={{
									__html: node.description.childMarkdownRemark.html
								}}
							/>
						</div>
					);
				})}
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allContentfulBlogPost(sort: { fields: createdDate, order: DESC }) {
			edges {
				node {
					slug
					title
					createdDate(formatString: "MMMM D, YYYY")
					description {
						childMarkdownRemark {
							html
						}
					}
				}
			}
		}
	}
`;
