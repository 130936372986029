import React from 'react';
import styled from 'styled-components';
import { rhythm } from '../utils/typography';

import Header from './Header';
import Footer from './Footer';

const Content = styled.div`
	margin-left: auto;
	margin-right: auto;
	max-width: ${rhythm(36)};
	padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`;

export default class Layout extends React.Component {
	render() {
		const { title, children } = this.props;

		return (
			<Content>
				<Header title={title} />

				<main>{children}</main>

				<Footer />
			</Content>
		);
	}
}
