import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	text-align: center;
`;

export default function Footer() {
	return <StyledFooter>© {new Date().getFullYear()}</StyledFooter>;
}
